import React from 'react';
import JobForm from 'components/pages/jobForm';
import { Router } from "@reach/router"

const Formulario = () => {

  return (
    <Router basepath="formulario">
      <JobForm path="/:jobform" />
    </Router>
  )
}

export default Formulario
