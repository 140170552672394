import React from "react"
import Layout from "components/layout/layout"
import Embed from "components/embed"

const JobForm = props => {
  return (
    <>
      <Layout pageTitle={`Formulário`}>
        <div className="u-wrapper u-wrapper--mb">
          <Embed src={props.jobform} />
        </div>
      </Layout>
    </>
  )
}

export default JobForm
