import React, { useEffect, useState } from "react"
import { getEmbedUrl, getSiteUrl } from "../../service"

const Embed = props => {
  const [iframeHeight, setIframeHeight] = useState()

  useEffect(() => {
    const iniTime = new Date().getTime()

    const checkIframeHeight = setInterval(() => {
      if (getSiteUrl().indexOf("localhost") === -1) {
        let iframeElement = window.frames["iframe"]
        if (
          iframeElement &&
          iframeElement["contentWindow"] &&
          iframeElement.contentWindow["document"] &&
          iframeElement.contentWindow.document["body"] &&
          iframeElement.contentWindow.document.body["scrollHeight"]
        ) {
          setIframeHeight(
            iframeElement.contentWindow.document.body.scrollHeight
          )
        }
        if (new Date().getTime() - iniTime > 15000) {
          clearInterval(checkIframeHeight)
        }
      } else {
        clearInterval(checkIframeHeight)
      }
    }, 50)

    return () => {
      clearInterval(checkIframeHeight)
    }
  }, [])

  return (
    <div className="c-embed" id="embed">
      <iframe
        title="embed"
        id="iframe"
        src={getEmbedUrl(props.src)}
        height={iframeHeight}
      />
    </div>
  )
}

export default Embed
